import styled from 'styled-components';
import { Row, Table } from 'antd';
import { media } from '@app/styles/themes/constants';
import { Dropdown } from '@app/components/common/Dropdown/Dropdown.styles';

export const TableSortDropdown = styled(Dropdown)`
  margin: 1.75rem 0;

  @media only screen and ${media.xl} {
    margin: 0;
  }
`;

export const PaymentHistoryInvoiceCol = styled.div`
  overflow: hidden;
  max-height: 120px;
  overflow-y: scroll;
  max-width: 100%;
  display: flex;
  flex-direction: column;
`;

export const PaymentHistoryTable = styled(Table)`
  overflow: auto;

  .ant-table-thead > tr > th {
    border-top: 1px solid rgba(var(--primary-rgb-color), 0.3);

    border-bottom: 1px solid rgba(var(--primary-rgb-color), 0.3);
    color: var(--primary-color);

    &::before {
      display: none;
    }
  }

  .ant-table-tbody > tr > td {
    border-bottom: 1px solid rgba(var(--primary--rgb-color), 0.3);
    font-weight: 600;
    font-size: 1rem;
  }

  .ant-table-tbody > tr:nth-child(even) {
    background: var(--secondary-background-selected-color);
  }

  .ant-empty-description {
    color: var(--primary-color);
  }
`;

export const AvatarWrapper = styled.div`
  display: flex;
  justify-content: center;
  border-radius: 50%;
  margin-right: 1em;
  border: 3px solid var(--primary1-color);

  background: linear-gradient(211.49deg, #0f82e0 15.89%, #5229c5 60.97%);

  & > span {
    margin: 0.5px;
  }
`;

export const FormControlWrapper = styled(Row)`
  display: flex;
  flex-direction: column-reverse;
  justify-content: stretch;
  margin: 0 30px;

  @media only screen and ${media.xl} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 30px 0;
  }

  @media (max-width: 1280px) {
    > .ant-btn {
      margin: 30px 0;

      &:last-child {
        margin-top: 0;
      }
    }
  }

  .ant-input-wrapper {
    display: flex;
  }
`;

export const RecipientWrapper = styled.div`
  display: flex;
  align-items: center;
  text-align: left;

  & > span:first-of-type {
    margin-right: 0.5rem;
  }
`;
