import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as S from './ProfileOverlay.styles';

export const EditProfile: React.FC = ({ ...props }) => {
  const { t } = useTranslation();

  return (
    <div {...props}>
      <S.Text>
        <Link to="/profile">{t('header.editProfile')}</Link>
      </S.Text>
      <S.Text>
        <Link to="/profile/appearance">{t('header.changeSettings')}</Link>
      </S.Text>
      <S.Text>
        <Link to="/logout">{t('header.logout')}</Link>
      </S.Text>
    </div>
  );
};
