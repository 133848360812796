import React, { useState } from 'react';
import { StarIcon } from 'lucide-react';

interface StarProps {
  filled: boolean;
  hovered: boolean;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  onClick: () => void;
}

const Star: React.FC<StarProps> = ({ filled, hovered, onMouseEnter, onMouseLeave, onClick }) => {
  return (
    <StarIcon
      size={24}
      style={{
        color: filled ? '#FACC15' : hovered ? '#FEF08A' : '#D1D5DB', // Tailwind's colors in hex
        transition: 'color 0.2s ease-in-out', // Equivalent to `transition-colors`
      }}
      fill={filled || hovered ? 'currentColor' : 'none'}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
    />
  );
};

interface StarRatingProps {
  totalStars?: number;
  onRatingChange?: (rating: number) => void;
  disabled?: boolean;
}

const StarRating: React.FC<StarRatingProps> = ({ totalStars = 5, onRatingChange, disabled }) => {
  const [rating, setRating] = useState(0);
  const [hoveredRating, setHoveredRating] = useState(0);

  const handleStarClick = (starIndex: number) => {
    if (disabled) {
      return;
    }

    const newRating = starIndex + 1;
    setRating(newRating);
    if (onRatingChange) {
      onRatingChange(newRating);
    }
  };

  return (
    <div className="flex space-x-1" style={{ cursor: 'pointer' }}>
      {[...Array(totalStars)].map((_, index) => (
        <Star
          key={index}
          filled={index < rating}
          hovered={!disabled && index < hoveredRating}
          onMouseEnter={() => setHoveredRating(index + 1)}
          onMouseLeave={() => setHoveredRating(0)}
          onClick={() => handleStarClick(index)}
        />
      ))}
    </div>
  );
};

export default StarRating;
