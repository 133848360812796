import { httpApi } from '@app/api/http.api';
import { GetUserClaimsByCreditorResponse } from './user.api';
import { PaymentDetailResponse } from '@app/pages/payment/PaySuccessPage';
import { EnumPaymentPaymentType } from '@app/domain/PaymentModel';

export interface GetPayoutsRequest {
  paymentDate?: {
    gte?: Date | string;
    lte?: Date | string;
    lt?: Date | string;
    gt?: Date | string;
  };
  type?: EnumPaymentPaymentType;
  isPlanned?: boolean;
}

export interface GetPayoutsResponse extends GetUserClaimsByCreditorResponse {
  reference: string;
  creditor: unknown;
  debtor: unknown;
  incomingPaymentsTotal?: number;
  feesTotal?: number;
  grossFeesTotal?: number;
  grossCostsTotal?: number;
  interestTotal?: number;
  grossInterestTotal?: number;
  debtCollectorFeesTotal?: number;
  grossDebtCollectorFeesTotal?: number;
  toRecoverTotal?: number;
  paidOutTotal?: number;
  payoutReference?: string;
  payoutFileUrl?: string;
  payoutDate?: string;
}

export interface GetInvoicesResponse extends GetPayoutsResponse {
  invoiceTotal?: number;
  invoiceReference?: string;
  invoiceFileUrl?: string;
  invoiceDate?: string;
  invoicePaid?: boolean;
}

type PAYMENT_PARAMS = {
  order_no: string;
  currency: string;
  amount: number;
};

export const getPayouts = (getPayoutsPayload: GetPayoutsRequest): Promise<GetPayoutsResponse> => {
  const parameters: Record<string, string | Date | boolean> = {};

  if (getPayoutsPayload.paymentDate?.lte) {
    parameters['paymentDate[lte]'] = getPayoutsPayload.paymentDate.lte;
  }

  if (getPayoutsPayload.paymentDate?.gte) {
    parameters['paymentDate[gte]'] = getPayoutsPayload.paymentDate.gte;
  }

  if (getPayoutsPayload.type) {
    parameters['type'] = getPayoutsPayload.type;
  }

  if (getPayoutsPayload.isPlanned !== undefined) {
    parameters['isPlanned'] = getPayoutsPayload.isPlanned;
  }
  return httpApi
    .get<GetPayoutsResponse>('payments/payouts', {
      params: parameters,
    })
    .then(({ data }) => data);
};

export const getCreditorInvoices = (getPayoutsPayload: GetPayoutsRequest): Promise<GetInvoicesResponse[]> => {
  const parameters: Record<string, string | Date | boolean> = {};

  if (getPayoutsPayload.paymentDate?.gte) {
    parameters['paymentDate[gte]'] = getPayoutsPayload.paymentDate.gte;
  }

  if (getPayoutsPayload.paymentDate?.lte) {
    parameters['paymentDate[lte]'] = getPayoutsPayload.paymentDate.lte;
  }

  if (getPayoutsPayload.type) {
    parameters['type'] = getPayoutsPayload.type;
  }

  if (getPayoutsPayload.isPlanned !== undefined) {
    parameters['isPlanned'] = getPayoutsPayload.isPlanned;
  }

  return httpApi
    .get<GetPayoutsResponse[]>('payments/creditorInvoices', {
      params: parameters,
    })
    .then(({ data }) => data)
    .catch((error) => {
      console.error('Error fetching creditor invoices:', error);
      throw error;
    });
};

export const generatePaymentPage = (orderNumber: string, currency: string, paymentAmount: number) => {
  if (!orderNumber || !paymentAmount) {
    return;
  }

  const parameters: PAYMENT_PARAMS = {
    order_no: orderNumber ?? '',
    currency: currency ?? 'EUR',
    amount: paymentAmount,
  };

  return httpApi
    .post('payments/initiate-payment', parameters)
    .then(({ data }) => data)
    .catch((error) => console.error('error: ', error));
};

export const getPaymentStatus = (claimReference: string, claimId?: string): Promise<PaymentDetailResponse> => {
  return httpApi
    .post('payments/payment-status', { ref: claimReference, claimId: claimId })
    .then(({ data }) => data)
    .catch((error) => console.error('error: ', error));
};
