import { useEffect } from 'react';

interface TrustpilotWidgetProps {
  currentLocale?: string;
  showTrustpilot?: boolean;
}

const TrustpilotWidget = ({ currentLocale, showTrustpilot }: TrustpilotWidgetProps) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
    script.async = true;
    script.onload = () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (window.Trustpilot) {
        const widgetElement = document.querySelector('.trustpilot-widget');
        if (widgetElement) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          window.Trustpilot.loadFromElement(widgetElement, true);
        }
      }
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [showTrustpilot]);

  return (
    <div style={{ width: 230, margin: '0 auto' }}>
      <div
        className="trustpilot-widget"
        data-locale={currentLocale}
        data-template-id="56278e9abfbbba0bdcd568bc"
        data-businessunit-id="668fb28bf462e383527966fc"
        data-style-height="52px"
        data-style-width="230px"
        style={showTrustpilot ? { opacity: '1' } : { opacity: '0', pointerEvents: 'none' }}
      >
        <a
          href={`https://${currentLocale}.trustpilot.com/review/www.debtist.de`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {showTrustpilot ? 'Trustpilot' : ''}
        </a>
      </div>
    </div>
  );
};

export default TrustpilotWidget;
