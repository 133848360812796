import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { Card } from 'components/common/Card/Card';
import { Skeleton, Typography, Space, Row, Col } from 'antd';
import Blob from '@app/components/common/Blob/Blob';
import * as S from './Payment.styles';
import { useParams } from 'react-router-dom';
import { getPaymentStatus } from '@app/api/payments.api';
import { createClaimActivityPublic } from '@app/api/claims.api';
import { ClaimModel } from '@app/domain/ClaimModel';
import StarRating from '@app/components/common/StarRating/StarRating';
import { SubtitleBalanceText } from '@app/components/nft-dashboard/Balance/Balance.styles';
import { SpaceRow } from './PayPage';
import Lottie from 'react-lottie';
import TrustpilotWidget from '@app/components/common/TrustPilot/TrustPilot';

export type PaymentDetailResponse = {
  status?: string;
  status_code?: number;
  status_text?: string;
  claim?: Partial<ClaimModel>;
};

const PaySuccessPage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [showTrustpilot, setShowTrustpilot] = useState(false);
  const [rating, setRating] = useState(0);

  const [paymentDetails, setPaymentDetails] = useState<PaymentDetailResponse | null>(null);

  // Get the current locale
  const currentLocale = i18n.language;

  const { claimReference, claimId } = useParams();

  // Animation config
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: require('@app/assets/lottie/success-animation.json'),
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice', // no idea wtf this value is
    },
  };

  const handleRatingChange = (newRating: number) => {
    if (rating !== 0) {
      return false;
    }
    setRating(newRating);

    if (newRating > 3) {
      setShowTrustpilot(true);
    } else {
      setShowTrustpilot(false);
    }
  };

  useEffect(() => {
    if (!paymentDetails) {
      setIsLoading(true);
      const getPaymentStatusDetails = async () => {
        let response: PaymentDetailResponse;
        if (claimReference) {
          try {
            response = await getPaymentStatus(claimReference, claimId);
            if (response.status === 'SUCCESS') {
              setPaymentDetails(response);

              if (claimId && claimReference) {
                try {
                  await createClaimActivityPublic({
                    claimId: claimId,
                    claimReference: claimReference,
                    activity: {
                      activityType: 'Response',
                      result: 'ReachedPromisedToPay',
                      isPlanned: false,
                    },
                  });
                } catch (e) {}
              }
            }
          } catch (error) {
            console.error('error:', error);
          }
        }
        setIsLoading(false);
      };

      getPaymentStatusDetails();
    }
  }, [claimReference, claimId, paymentDetails]);

  return (
    <S.Wrapper>
      <S.BackgroundWrapper>
        <S.ContainerWrapper>
          <Blob />

          <PageTitle>{t('common.paySuccess')}</PageTitle>
          <Card padding={20} style={{ width: 'max-content', textAlign: 'center' }}>
            <Space direction={'vertical'}>
              <Skeleton loading={isLoading}>
                <Lottie options={defaultOptions} height={150} width={150} />
                <Typography.Text style={{ color: 'var(--success-color)', display: 'block', minWidth: 380 }}>
                  {t('common.paySuccess')}: {claimReference}
                </Typography.Text>
                <Row gutter={20}>
                  <Col span={24}>
                    <SpaceRow justify="center">
                      <SubtitleBalanceText>
                        {rating === 0 ? t('common.rateUs') : t('popconfirm.thankYou')}
                      </SubtitleBalanceText>
                    </SpaceRow>
                    {!showTrustpilot && <StarRating disabled={rating !== 0} onRatingChange={handleRatingChange} />}

                    <div style={{ width: 230, margin: '0 auto' }}>
                      <TrustpilotWidget currentLocale={currentLocale} showTrustpilot={showTrustpilot} />
                    </div>
                  </Col>
                </Row>
              </Skeleton>
            </Space>
          </Card>
        </S.ContainerWrapper>
      </S.BackgroundWrapper>
    </S.Wrapper>
  );
};

export default PaySuccessPage;
