import React from 'react';
import { Col, Row } from 'antd';
import { ProfileDropdown } from '../components/profileDropdown/ProfileDropdown/ProfileDropdown';
import * as S from '../Header.styles';
import styled from 'styled-components';
import { Button } from '@app/components/common/buttons/Button/Button';
import { LeftOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

interface DesktopHeaderProps {
  isTwoColumnsLayout: boolean;
}

const BackButton = styled(Button)`
  font-size: 1rem;
  font-weight: 600;
  padding: 0;
  height: unset;

  > span {
    background-color: transparent;
  }
`;

export const DesktopHeader: React.FC<DesktopHeaderProps> = ({ isTwoColumnsLayout }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const isClaimDetailsPage = /^\/claims\/[^/]+$/.test(location.pathname);

  const leftSide = isTwoColumnsLayout ? (
    <S.SearchColumn xl={16} xxl={17}>
      <Row justify="space-between">
        <Col xl={15} xxl={12}></Col>
      </Row>
    </S.SearchColumn>
  ) : (
    <>
      <Col lg={10} xxl={8}>
        {/* CHeck if user is on claims detail page */}
        {isClaimDetailsPage &&
          !location.pathname.includes('/debtcollection') &&
          !location.pathname.includes('/reminders') && (
            <BackButton icon={<LeftOutlined />} type="text" onClick={() => history.back()}>
              {t('common.backToOverview')}
            </BackButton>
          )}

        {/* {!isClaimDetailsPage && language picker ready to be added for later
          !location.pathname.includes('/debtcollection') &&
          !location.pathname.includes('/reminders') && (
            <div style={{ left: 120, top: -20, position: 'absolute' }}>
              <LanguagePicker isExtended />
            </div>
          )} */}
      </Col>
    </>
  );

  return (
    <Row justify="space-between" align="middle">
      {leftSide}

      <S.ProfileColumn xl={8} xxl={7} $isTwoColumnsLayout={isTwoColumnsLayout}>
        <Row align="middle" justify="end" gutter={[10, 10]}>
          <Col>
            <ProfileDropdown />
          </Col>
        </Row>
      </S.ProfileColumn>
    </Row>
  );
};
